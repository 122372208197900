<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 间推
 * @Date: 2020-12-25 20:52:54
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-11 16:30:00
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/Interposition.vue
-->
<template>
  <div class="user_content">
    <div class="waller_total">
      <div class="head">
        <img :src="avatar" alt="">
      </div>
      <div class="name">{{ name }}</div>
    </div>
    <div class="waller_list">
      <div class="invit_to">
        <div>
          <span>{{ total }}</span>
          <span>总人数</span>
        </div>
      </div>
      <div class="invitlist">
        <div class="title">邀请明细</div>
        <ul>
          <li v-for="(item,index) in list" :key="index">
            <div class="head">
              <img :src="item.avatar" alt="">
            </div>
            <div class="test">
              <div>{{ item.name }}</div>
              <div>{{ item.mobile }}</div>
              <div>{{ item.joinTime }}</div>
            </div>
            <div>
              <van-icon name="arrow" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Toast } from 'vant'

Vue.use(Icon).use(Toast)
import {
  invitechildList
} from '@/services/userApi'

export default {
  data() {
    return {
      name: '',
      avatar: '',
      shopNum: '',
      total: '',
      list: [],
      memberId: ''
    }
  },
  created() {
    this.memberId = this.$route.query.memberId
    this.getList()
  },
  methods: {
    getList() {
      invitechildList(this.memberId, 1).then(res => {
        if (Number(res.code) === 200) {
          this.avatar = res.data.member.avatar
          this.name = res.data.member.name
          this.total = res.data.team.total
          this.list = res.data.list
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .waller_total {
    padding: 30px 0;
    background: #F7263C;
    height: 150px;

    .head {
      margin: auto;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #fff;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      display: block;
      color: #FFFFFF;
      margin-top: 10px;
    }
  }

  .waller_list {
    width: 93%;
    position: absolute;
    top: 120px;
    margin: 13px;
    padding: 4px;

    .invit_to {
      display: flex;
      background: #FFFFFF;
      border-radius: 7px;

      & > div {
        flex: 12;

        & > span {
          display: block;
          margin: 10px 0;
        }

        & > span:nth-child(1) {
          color: #E33F44;
          font-size: 17px;
        }
      }
    }
  }

  .invitlist {
    border-radius: 7px;
    margin-top: 10px;
    padding: 13px;
    background: #FFFFFF;

    .title {
      text-align: left;
      font-size: 14px;
    }

    & > ul li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      // border-bottom: 1px solid #8888;
      padding: 10px;

      .head {
        width: 54px;
        height: 54px;
        background: #F7263C;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .test {
        flex: 6;
        margin: 0 10px;
        text-align: left;

        & > div {
          color: #999999;
          font-size: 12px;
          padding: 2px 0;
        }

        & > div:nth-child(1) {
          font-size: 13px;
          color: #000000;
        }
      }
    }
  }
}

</style>
